@import "../../../../styles/abstracts/mixins";
@import "../../../../styles/abstracts/variables";

.sub__div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 60px 25px 80px 25px; 
    background-color: $color-background-1;
    height: 100vh;
}

.sub__card {
    width: 352px;
    height: 525px;
    border-radius: 16px;
    background-color: $color-white;
    border: 1px solid $color-background-1;
    padding: 20px 10px;
    @include flex(column, space-between);
}

.sub__card--head {
    text-align: center;
    padding-bottom: .5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

// .sub__card--body {
//     @include flex(column, space-between);
// }

.sub__card--head-5 {
    @include Inter-600();
    font-size: 20px;
}

.sub__card--head-3 {
    @include Inter-600();
    font-size: 36px;
    margin: 1rem auto;
}

.sub__card--mid {
    margin-bottom: 1rem;
}

.sub__card--mid-ul {
    top: 5px;
}

.sub__card--mid-li {
    font-size: 16px;
    @include Inter-400();
    @include flexRow(row);
    margin-bottom: 12px;
}

.sub__card--mid-icn {
    width: 24px !important;
    height: 24px;
    border-radius: 12px;
    background-color: $color-secondary-bg-1;
    color: $color-secondary-bg-2;
    margin-right: 12px;
    padding: 4px;
    font-size: 16px;
}

.sub__card--bottom {
    margin: 10px auto;
    position: relative;
    justify-content: center;
}

#mid_3 {
}
