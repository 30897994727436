@import "../../../../styles/abstracts/mixins";
@import "../../../../styles/abstracts/variables";


.claim__div {
    @include flexRow(row);
    padding: 20px 30px;
}

.claim__div--top {
    width: 50%;
}

.claim__div--bottom {
    width: 50%;
}

.claim__card {
    margin-bottom: 1.5rem
}

.claim__card--head {
    @include flexRow(row);
    margin-bottom: .5rem;
}

.claim__card--indicator {
    width: 5px;
    height: 24px;
    border-radius: 100px;
    margin-right: 15px;
    background-color: #EB5017;
}

.claim__card--head-3 {
    @include Inter-500();
    font-size: 18px;
}

.claim__card--body {
    width: 454px;
    // height: 252px;
    margin-left: 20px;
}

.claim__card--para {
    @include Inter-400();
    font-size: 16px;
    color: #1B1B1B;
    line-height: 28px;
}

.claim_2 {

}