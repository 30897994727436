@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

.team__div {
  padding: 4rem 3rem;
  @include flexRow(column);
  background-color: $color-background-1;
  height: 100%;
}

.team__table {
  $iconwidth: 50%;
}

.pending_invitation__container {
  height: 100%;
  background-color: $color-background-1;
  padding: 2rem;
}

.pending_invitation__div {
  height: 100%;
  padding: 4rem 3rem;
  @include flexRow(column);
  background-color: $color-background-1;
}
