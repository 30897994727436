//COLORS
$color-primary: #F8C877;
$color-primary-light-1: #FEF6E7;
$color-primary-light: #F19A02;
$color-primary-dark: #CD932A;
$color-primary-darker: #FF8300;
$color-primary-bg: #FFECE5;

$color-secondary: #2DBA21;
$color-secondary-light: #D4F8D0;
$color-secondary-light-2: #E5FFF0;
$color-secondary-dark: #2DBA21;
$color-secondary-dark-2: #075201;
$color-secondary-bg: #E7F6EC;
$color-secondary-bg-1: #D1FADF;
$color-secondary-bg-2: #12B76A;

$color-tertiary-light-2: #F9FAFB;
$color-tertiary-light-1: #454cad;
$color-tertiary-light: #3247FC;
$color-tertiary: #4A2EF2;
$color-tertiary-dark-1: #8C97FD;
$color-tertiary-dark: #003889;
$color-tertiary-light-3 : #EEECF5;

$color-gold-light: #FCAF92;
$color-gold-dark: #F56630;

$color-text-dark: #1B1B1B;

$color-background: #D0D5DD;
$color-background-1: #F0F2F5;
$color-background-2: #EEECF5;
$color-background-3: #F0F5FD;
$color-background-4: #E4E7EC;
$color-background-dark: #1B1B1B;

$color-border: #BAC0F4;

$color-number: #98A2B3;



$color-white: #fff;
$color-black: #000;
$color-red: #F32121;
$color-red-2: #F12525;



//FONT
$default-font-size: 1.6rem;
