@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

.sidenav {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $color-background-4;
}

.sidenav__top {
  height: 45%;
  padding-left: 10px;
  padding-top: 30px;
  border-bottom: 1px solid $color-background-4;
}

.sidenav__links {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  font-family: Inter;
  padding-left: 10px;
  height: 35px;

  .sidenav__icon {
    margin-right: 5px;
  }
}

.sidenav__list {
  width: 100%;
  height: 28px;
  background-color: $color-white;
  margin: 10px 0;
  padding: 10px auto 10px 10px;
}

.active {
  background-color: $color-tertiary-dark;
  width: 95%;
  border-radius: 4px;
  color: $color-white;
}

.sidenav__img {
  margin-bottom: 1rem;
}

.sidenav__logo {
  height: 55px !important;
  width: 80px !important;
}

.sidenav__top--nav {
  padding-top: 5px;
}

.sidenav__bottom {
  height: 50%;
  padding-top: 3rem;
  padding-left: 10px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidenav__user--div {
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5px 0 0;
  align-items: center;
}

.sidenav__user--img {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}

.sidenav__user--details {
  font-size: 12px;
  margin: 0px 4px;
  width: 70%;

  h4 {
    font-weight: 700;
  }

  p {
    width: 80%;
  }
}

.sidenav__user--log {
  color: $color-red;

  &-icon {
    height: 24px !important;
  }
}
