.chartCard {
  border-radius: 0.4rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
  background-color: #e7f6ec;

  &:first-child {
    background-color: #fef6e7;

    .indicator {
      border: 2px solid #f19a02;
    }
  }

  &:nth-child(2) {
    background-color: #ffece5;

    .indicator {
      border: 2px solid #f56630;
    }
  }

  &:last-child {
    background-color: #f9fafb;

    .indicator {
      border: 2px solid #3247fc;
    }
  }

  .chartTop {
    display: flex;
    flex-direction: row;
  }

  .indicator {
    border: 2px solid #4caf50;
    margin-right: 0.5rem;
    border-radius: 5px;
  }
}
