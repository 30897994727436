
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.double__btn--div {
    @include flexRow(row);
}

.double__btn--first {
    background-color: $color-white !important;
    border: 1px solid $color-tertiary-dark !important;
    color: $color-black !important;
    margin-right: 1rem !important;
}

.double__btn--second {
    background-color: $color-tertiary-dark !important;
    border: 1px solid $color-tertiary-dark !important;
    color: $color-white !important;
}