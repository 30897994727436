// Imports
//
// @import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);
// @import url('https://fonts.googleapis.com/css?family=Roboto');

// Vars and Reset
//
// $theme:       #454cad;
// $dark-text:   #5f6982;

// html,body,* { box-sizing: border-box; font-size: 16px;}
// html,body   { height: 100%; text-align: center;}
// body        { padding: 2rem; background: #f8f8f8;}

@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

// Upload Demo
//
.uploader {
  display: block;
  clear: both;
  margin-top: 10px;
  width: 100%;
  height: 207px;
  max-width: 100%;
  border-radius: 12px;
  background-color: $color-white;

  label {
    float: left;
    clear: both;
    width: 100%;
    padding: 4rem 1.5rem;
    text-align: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 7px;
    border: 3px solid $color-background-4;
    transition: all 0.2s ease;
    user-select: none;

    &:hover {
      border: 2px solid $color-tertiary-light;
    }
  }

  #start {
    justify-content: center;
    float: left;
    clear: both;
    width: 100%;
    justify-content: center;

    &.hidden {
      display: none;
    }
  }

  #response {
    float: left;
    clear: both;
    width: 100%;

    &.hidden {
      display: none;
    }

    #messages {
      margin-bottom: 0.5rem;
    }
  }

  #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;

    &.hidden {
      display: none;
    }
  }

  progress,
  .progress {
    // appearance: none;
    display: inline;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    height: 8px;
    border: 0;
    border-radius: 4px;
    background-color: #eee;
    overflow: hidden;
  }

  .progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background-color: #eee;
  }

  .progress[value]::-webkit-progress-value {
    background: linear-gradient(to right, darken(#454cad, 8%) 0%, #454cad 50%);
    border-radius: 4px;
  }

  .progress[value]::-moz-progress-bar {
    background: linear-gradient(to right, darken(#454cad, 8%) 0%, #454cad 50%);
    border-radius: 4px;
  }

  input[type="file"] {
    display: none;
  }

  div {
    margin: 0 0 0.5rem 0;
    color: #5f6982;
  }

  .btn {
    display: inline-block;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    clear: both;
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: initial;
    border: none;
    border-radius: 0.2rem;
    outline: none;
    padding: 0 1rem;
    height: 36px;
    line-height: 36px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    background: #454cad;
    border-color: #454cad;
    cursor: pointer;
  }
}

.upload__icon--div {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: #f2f4f7;
  border: 7px solid #f9fafb;
  border-radius: 50%;
  margin-left: 45% !important;

  i {
    color: #003889;
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: 30px;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;
  }
}

.upload__text--div {
  @include flexRow(row);
  justify-content: center;
  font-size: 18px;
}

.upload__text--head {
  color: #003889;
  font-weight: 500;
  margin-right: 5px;
}
