@import "../../../../styles/abstracts/mixins";
@import "../../../../styles/abstracts/variables";


.usings__div {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    padding: 40px 45px;
}


.usings__card--head {
    @include flexRow(row);
    margin-bottom: .5rem;
}

.usings__card--indicator {
    width: 5px;
    height: 24px;
    border-radius: 100px;
    margin-right: 15px;
    background-color: #EB5017;
}

.usings__card--head-3 {
    @include Inter-500();
    font-size: 18px;
}

.usings__card--body {
    width: 454px;
    height: 252px;
    margin-left: 20px;
}

.usings__card--para {
    @include Inter-400();
    font-size: 16px;
    color: #1B1B1B;
    line-height: 28px;
}