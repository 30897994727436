@import "../../../../styles/abstracts/variables";

.mobileTable {
  width: 100%;
  display: flex;
  flex-direction: column;

  &Item {
    border: solid $color-primary-bg;
    border-width: 1px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 36px 12px;

    &Row {
      display: flex;
      gap: 12px;
    }

    &HeaderCell {
      flex: 1;
      font-size: 14px;
      font-weight: 700;
      max-width: calc(50% - 6px);
      overflow: hidden;
      overflow-wrap: break-word;
    }

    &ValueCell {
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      max-width: calc(50% - 6px);
      overflow: hidden;
      text-align: end;
      overflow-wrap: break-word;
    }

    &Hover {
      cursor: pointer;
      transition: background-color 200ms ease-in-out;
    }
    &Hover:hover {
      background-color: $color-gold-light;
    }
  }
}

.loading_or_emptyTable {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
