@import "../../../styles/abstracts/variables";

.pagination-div {
  width: 100%;
  margin-top: 3rem;
  border-radius: 12px;
  height: 68px;
  text-align: center;
  padding-top: 1rem;
  background-color: $color-white;
}

.pagination-container {
  justify-content: center;
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 36px;
    width: 36px;
    text-align: center;
    margin: auto 4px;
    color: $color-number;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 6px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &:first-child {
      font-size: 18px;
      margin-right: .5rem;
      border: 1px solid $color-background;
      padding: 0px 6px;
      font-size: 25px;
      color: $color-background-dark;
    }

    &:last-child {
      font-size: 18px;
      margin-left: .5rem;
      border: 1px solid $color-background;
      padding: 0px 6px;
      font-size: 25px;
      color: $color-background-dark;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      color: $color-background-dark;
      border: 1px solid $color-tertiary-dark;
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
      }

      &.left {
        // transform: rotate(-135deg) translate(-50%);        
      }

      &.right {
        // transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        // border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        // border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}