@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

.topnav {
    margin: 5px 30px;
    padding-bottom: 10px;
    border-bottom: 2px solid $color-background-4;
}

.topnav__top {
    text-align: left;
    @include flexRow(row);
    width: 50%;
}

.topnav__links {
    font-size: 14px;
    font-weight: 600;
    font-family: Inter;
    padding-bottom: 10px;
    height: 28px;
    color: #667085;
}

.topnav__list {
    width: 100%;
    padding: 10px auto 10px 10px;
}

.active {
    color: $color-tertiary-dark;
    border-bottom: 2px solid $color-tertiary-dark;
    width: 100%;
}
