@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&display=swap");
//MEDIA QUERY MANAGER
/*
0-600px:   Phone
600-900px:   Tablet portrait
900-1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :   Big desktop
*/
/*
$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop
ORDER: Base + typography > general layout + grid > page layout > components
1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } //1800px
  }
}
//Montserrat font family
@mixin Inter-300 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}
//Normal
@mixin Inter-400 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
//Medium
@mixin Inter-500 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
//Semi-bold
@mixin Inter-600 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}
@mixin Inter-700 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
}
@mixin Inter-800 {
  font-family: "Inter", sans-serif;
  font-weight: 800;
}
@mixin flexBetween($gap) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $gap;
}
@mixin flex($direct, $justify) {
  display: flex;
  flex-direction: $direct;
  justify-content: $justify;
}
@mixin flexRow($direct) {
  display: flex;
  flex-direction: $direct;
}
@mixin typographyStyle($size, $weight, $line) {
  font-size: $size;
  line-height: $line;
  font-weight: $weight;
}
@mixin typographyStyle1($size, $line) {
  font-size: $size;
  line-height: $line;
}

@mixin inputBorder($br) {
  border-radius: $br;
    border: 1px solid #D0D5DD;
}
// @mixin clearfix {
//     &::after {
//          content: "";
//          display: table;
//          clear: both;
//     }
// }
// @mixin absCenter {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
// }
