@import "../../../../styles/abstracts/mixins";
@import "../../../../styles/abstracts/variables";

.tableWrapper {
  width: 100%;
  border: 1px solid $color-background-4;
  border-collapse: collapse;
  border-radius: 12px;
  border-style: hidden;

  & > h2 {
    font-weight: 400;
    height: 55px;
    text-align: left;
    padding-left: 25px;
    padding-top: 15px;
  }
}
