@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.iconsWrapper {
  display: flex;
  gap: 10px;
  align-items: center;

  .icon {
    border: 1px solid $color-background;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    padding: 0 3px;
    width: 25px;
  }
}