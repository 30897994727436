@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

.logs__main {
  background-color: $color-background-1;
  padding: 2rem;
}

.logs__div {
  margin-top: 2rem;
  padding: 2rem 3rem;
  @include flexRow(column);
}
